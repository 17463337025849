import React, { useState } from 'react';
import BtnAndroid from '../../components/HImage/BtnDowloadAndroid';
import BtnIos from '../../components/HImage/BtnDownloadIOS';
import BtnHuawei from '../../components/HImage/BtnDownloadHuawei';
import link from '../../../config/link'
import { injectIntl } from "gatsby-plugin-intl"

import "react-multi-carousel/lib/styles.css";
import './bannerRider.scss'

export default injectIntl(({intl, countryCode, appGalleryPax}) => {
	const [activeIndex, setActiveIndex] = useState(0);

	return (
    <section id="mBaner_rider">
        <div className="bannerImg">
            {/* <HImage filename="rider/Banner-Mobile.png" className="imgBaner d-block d-sm-none"/>
            <HImage filename="rider/Banner.png" className="imgBaner d-none d-sm-block"/> */}
            {/* <img src={`../../../${countryCode}/images/rider/driver_banner.jpg`} className="bannerImage d-none d-sm-block" */}
            <img src={`../../../${countryCode}/images/rider/banner_mobile.jpg`} className="imgBaner d-block d-sm-none" />
            <img src={`../../../${countryCode}/images/rider/banner.jpg`} className="imgBaner d-none d-sm-block" />
            </div>
            <div className="contentBaner">
            <h2>{intl.formatMessage({id: "rider.banner_1"}, {br: <br />})}</h2>
            <div className="banner_dowload">
              <ul>
                <li>
                  <a href={countryCode !== 'jo' ? link.googlePlay : link.appStoreJordan} target="_blank">
                    <BtnAndroid intl={intl} />
                  </a>
                </li>
                <li>
                  <a href={countryCode !== 'jo' ? link.appStore : link.appStoreJordan} target="_blank">
                    <BtnIos intl={intl} />
                  </a>
                </li>
                {
                  appGalleryPax &&
                  <li>
                    <a href={appGalleryPax} target="_blank">
                      <BtnHuawei intl={intl} />
                    </a>
                  </li>
                }
              </ul>
            </div>
            </div>
        </section>
	)
})