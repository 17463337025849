import HImage from '../../components/HImage/HImageByPath';
import React, { Component } from 'react'
import { Row, Col,Card} from 'react-bootstrap';
import { injectIntl } from "gatsby-plugin-intl"
import CountryContext from '../../context/CountryContext'

import './Riders.scss';
class SafetyForRider extends Component {
    static contextType = CountryContext;
    render() {
        const { intl } = this.props || {};
        let context = this.context;
        return (
            <section id="ride-safety">
            <div className="h-container">
            <h2>{intl.formatMessage({id: "rider.safety.title"}, {br: <br />})}</h2>
            <Row>
                <Col xs="6" md="6">
                    <div className="safety_content">
                        <div className="icon_safety">
                             <HImage filename="rider/img4-1.png" className="be_icon"/>
                        </div>
                        <div>
                            <h4>{intl.formatMessage({id: "rider.safety.content_1.title"}, {br: <br />})}</h4>
                            <p>{intl.formatMessage({id: "rider.safety.content_1.content"}, {br: <br />})}</p>
                        </div>
                    </div>
                </Col>
                <Col xs="6" md="6">
                    <div className="safety_content">
                        <div className="icon_safety">
                            <HImage filename="rider/img4-2.png" className="be_icon"/>
                        </div>
                    <div>
                        <h4>{intl.formatMessage({id: "rider.safety.content_2.title"}, {br: <br />})}</h4>
                        <p>{intl.formatMessage({id: "rider.safety.content_2.content"}, {br: <br />})}</p>
                        {
                            context.country == 'gb' &&
                            <p className="safety_note">{intl.formatMessage({id: "rider.safety.content_2.content_2"}, {br: <br />})}</p>
                        }
                    </div>
                    </div>
                </Col>
                <Col xs="6" md="6">
                    <div className="safety_content">
                        <div className="icon_safety">
                        <HImage filename="rider/img4-3.png" className="be_icon"/>
                        </div>
                        <div>
                        <h4>{intl.formatMessage({id: "rider.safety.content_3.title"}, {br: <br />})}</h4>
                            <p>{intl.formatMessage({id: "rider.safety.content_3.content"}, {br: <br />})}</p>
                        </div>
                    </div>
                </Col>

                <Col xs="6" md="6">
                    <div className="safety_content">
                        <div className="icon_safety">
                            <HImage filename="rider/img4-4.png" className="be_icon"/>
                        </div>
                        <div>
                        <h4>{intl.formatMessage({id: "rider.safety.content_4.title"}, {br: <br />})}</h4>
                            <p>{intl.formatMessage({id: "rider.safety.content_4.content"}, {br: <br />})}</p>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
        </section>

);
}
}

export default injectIntl(SafetyForRider);