import React, { Component } from 'react'
import { Row, Col ,Card} from 'react-bootstrap';
import HImage from '../../components/HImage/HImageByPath';
import './Riders.scss';
import { injectIntl } from "gatsby-plugin-intl"

class EnjoyYourRide extends Component {
    render() {
        const { intl } = this.props || {};
        return (
            <section id="enjoy-rider">
                <div className="h-container">
                    <h2>{intl.formatMessage({id: "rider.Enjoy.title"}, {br: <br />})}</h2>
                    <div className="enjoy_rider_main">
                        <div className="enjoy_rider_content">
                            <HImage filename="rider/img2-1.png" className="be_icon"/>
                            <h4>{intl.formatMessage({id: "rider.Enjoy.content_1.title"}, {br: <br />})}</h4>
                            <p>{intl.formatMessage({id: "rider.Enjoy.content_1.content"}, {br: <br />})}</p>
                        </div>
                        <div className="enjoy_rider_content">
                            <HImage filename="rider/img2-2.png" className="be_icon"/>
                            <h4>{intl.formatMessage({id: "rider.Enjoy.content_2.title"}, {br: <br />})}</h4>
                            <p>{intl.formatMessage({id: "rider.Enjoy.content_2.content"}, {br: <br />})}</p>
                        </div>
                        <div className="enjoy_rider_content">
                            <HImage filename="rider/img2-3.png"className="be_icon"/>
                            <h4>{intl.formatMessage({id: "rider.Enjoy.content_3.title"}, {br: <br />})}</h4>
                            <p>{intl.formatMessage({id: "rider.Enjoy.content_3.content"}, {br: <br />})}</p>
                        </div>
                    </div>
                </div>
            </section>

);
}
}

export default injectIntl(EnjoyYourRide);