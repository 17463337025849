import React, { Component } from 'react'
import { Row, Col, Card } from 'react-bootstrap';
import HImage from '../../components/HImage/HImageByPath';
import { injectIntl } from "gatsby-plugin-intl"

import './Riders.scss';
class BookingARide extends Component {
    render() {
        const { intl, countryCode } = this.props || {};
        let customerImage1 = `../../../${countryCode}/images/rider/img3-1.png`;
        let customerImage2 = `../../../${countryCode}/images/rider/img3-2.png`;
        let customerImage3 = `../../../${countryCode}/images/rider/img3-3.png`;
        if (intl.locale === 'ge-ka') {
            customerImage1 = `../../../${countryCode}/images/rider/img3-1-ka.png`;
            customerImage2 = `../../../${countryCode}/images/rider/img3-2-ka.png`;
            customerImage3 = `../../../${countryCode}/images/rider/img3-3-ka.png`;
        } else if (intl.locale === 'ge-ru') {
            customerImage1 = `../../../${countryCode}/images/rider/img3-1-ru.png`;
            customerImage2 = `../../../${countryCode}/images/rider/img3-2-ru.png`;
            customerImage3 = `../../../${countryCode}/images/rider/img3-3-ru.png`;
        }
        return (
            <section id="book-a-ride">
                <div className="h-container">
                    <h2>  {intl.formatMessage({ id: "rider.booking.title" }, { br: <br /> })}</h2>
                    <Row className="row-booking">
                        <Col xs="12" md="4">
                            <div className="content_booking">
                                {/* <HImage filename="rider/img3-1.png" className="be_icon"/> */}
                                <img src={customerImage1} className="be_icon" />
                                <h4>{intl.formatMessage({ id: "rider.booking.content_1.title" }, { br: <br /> })}</h4>
                                <p>{intl.formatMessage({ id: "rider.booking.content_1.content" }, { br: <br /> })}</p>
                            </div>
                        </Col>
                        <Col xs="12" md="4">
                            <div className="content_booking">
                                {/* <HImage filename="rider/img3-2.png" className="be_icon"/> */}
                                <img src={customerImage2} className="be_icon" />
                                <h4>{intl.formatMessage({ id: "rider.booking.content_2.title" }, { br: <br /> })}</h4>
                                <p>{intl.formatMessage({ id: "rider.booking.content_2.content" }, { br: <br /> })}</p>
                            </div>
                        </Col>
                        <Col xs="12" md="4">
                            <div className="content_booking">
                                {/* <HImage filename="rider/img3-3.png" className="be_icon"/> */}
                                <img src={customerImage3} className="be_icon" />
                                <h4>{intl.formatMessage({ id: "rider.booking.content_3.title" }, { br: <br /> })}</h4>
                                <p>{intl.formatMessage({ id: "rider.booking.content_3.content" }, { br: <br /> })}</p>
                            </div>
                        </Col>

                    </Row>
                </div>
            </section>
        );
    }
}

export default injectIntl(BookingARide);