import React, { useContext, useRef } from "react"
import { graphql } from "gatsby"
import BannerRider from './../containers/BanerRider/BannerRider';
import SignLayout from './../layouts/SignLayout';
import BookingARide from './../containers/Riders/BookingARide';
import EnjoyYourRide from './../containers/Riders/EnjoyYourRide';
import SafetyForRider from './../containers/Riders/SafetyForRider';
import FleetService from './../containers/FleetService/FleetService';
import SEO from '../components/SEO/SEO';
import CountryContext from '../context/CountryContext'
let title = "Ride with GOJO to save more"
let description = "Sign up for the GOJO app to take a safe and affordable ride. Save time and money on your next trip."
const Rider = ({ data: { riderJson } }) => {
    const Context = useContext(CountryContext)
    const countryCode = Context.country
    return (
        <SignLayout>
            <SEO title={title} description={description} />
            <BannerRider 
                countryCode={countryCode}  
                appGalleryPax={Context.fleetInfo?.fleet?.socialSetting?.appGalleryPax} 
            />
            <FleetService countryCode={countryCode}/>
            <EnjoyYourRide />
            <BookingARide countryCode={countryCode}/>
            <SafetyForRider />
        </SignLayout>
)
}

export default Rider;

export const query = graphql`
query Rider($locale: String!) {
    riderJson: allFile(
        filter: { name: { eq: $locale }, relativeDirectory: { eq: "rider" } }
    ) {
        edges {
            node {
                childRiderJson {
                    fleetService {
                        title
                        subtitles
                        descriptions
                    }
                    enjoyYourRide {
                        title
                        subSilder {
                            title
                            image
                            descriptions
                        }
                    }
                    bookingARide {
                        title
                        subSilder {
                            title
                            image
                            descriptions
                        }
                    }
                    safetyForRider {
                        title
                        subSilder {
                            title
                            image
                            descriptions
                        }
                    }

                }
            }
        }
    }
}
`